/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import useOnClickOutside from 'use-onclickoutside';
import { IS_FINISHED, SHOW_DODO_BIRD, IS_AIRDROP_FINISHED } from '@/config';
import { useResponsive } from '@/hooks/useResponsive';
import useApplicationContext from '@/providers/applicationContext';
import { useAuthContext } from '@/providers/authProvider';
import {
  dashboardIcon,
  dominLogo,
  logoutIcon,
  navAirdropIcon,
  navContributeIcon,
  navDodobirdIcon,
} from '@/styles/imgMapping';
import { truncateEthAddress } from '@/utils/truncate-eth-address';
import { SuccessModal } from '../SuccessModal';
import {
  StyledBackDrop,
  StyledContainer,
  StyledDropdownContent,
  StyledGroup,
  StyledHeader,
  StyledImage,
  StyledItem,
  StyledNav,
} from './Styles';
import { ToggleIcon } from './ToggleIcon';

type Props = {
  walletAddress: string;
  isMainnet: boolean;
  currentChainType: string;
  onResetAuth: () => void;
  onLogoClick: () => void;
};

const Navbar = ({ walletAddress, isMainnet, currentChainType, onResetAuth, onLogoClick }: Props) => {
  const { isSmallerThanTablet } = useResponsive();
  const [dropdown, setDropdown] = useState(false);
  const truncatedAddress = truncateEthAddress(walletAddress);
  const [open, setOpen] = useState(false);
  const { address, isConnected } = useWeb3ModalAccount();
  const { authToken } = useAuthContext();
  const { popup, setPopup, updateRank, handlePopupClose } = useApplicationContext();

  const { open: openModal } = useWeb3Modal();

  const history = useHistory();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    onResetAuth();
    setDropdown(false);
  };

  const handleContributeClick = (event: any) => {
    if (IS_FINISHED) {
      event.preventDefault();
      setPopup({
        opened: true,
        title: 'One-time and Daily quests finished.',
        description: IS_AIRDROP_FINISHED ? '' : 'Please visit AirDrop to earn more $DOMIN!',
      });
    }
  };

  const handleAirdopClick = (event: any) => {
    if (IS_AIRDROP_FINISHED) {
      event.preventDefault();
      setPopup({
        opened: true,
        title: 'Airdrop campaigns finished.',
        description: '',
      });
    }
  };

  return (
    <>
      {isSmallerThanTablet && open && (
        <StyledBackDrop
          hasBackDrop
          onClick={() => {
            setOpen(false);
          }}
        />
      )}
      <StyledHeader>
        <StyledNav>
          <StyledContainer>
            <StyledImage alt="domin logo" src={dominLogo} />
            {isSmallerThanTablet && <ToggleIcon onClick={handleClick} open={open} />}
            <StyledGroup isSmallerThanTablet={isSmallerThanTablet} open={open}>
              {!isSmallerThanTablet && (
                <>
                  <StyledItem>
                    <img className="icon" alt="account icon" src={dashboardIcon} />
                    <Link to="/">Intro</Link>
                  </StyledItem>
                  {authToken && (
                    <>
                      <StyledItem>
                        <img className="icon" alt="account icon" src={navAirdropIcon} />
                        <Link to="/airdrop" onClick={handleAirdopClick}>AirDrop</Link>
                      </StyledItem>
                      <StyledItem>
                        <img className="icon" alt="account icon" src={navContributeIcon} />
                        <Link to="/contribute" onClick={handleContributeClick}>
                          ContriBute
                        </Link>
                      </StyledItem>
                    </>
                  )}
                  {authToken && SHOW_DODO_BIRD && (
                    <StyledItem>
                      <img className="icon" alt="account icon" src={navDodobirdIcon} />
                      <Link to="/dodobird">DoDo Bird</Link>
                    </StyledItem>
                  )}
                  {authToken && (
                    <StyledItem
                      onClick={() => {
                        if (walletAddress) {
                          handleLogout();
                        } else {
                          openModal();
                        }
                      }}
                    >
                      {walletAddress && <img className="icon" alt="account icon" src={logoutIcon} />}
                      <span className="content">{truncatedAddress || 'Connect'}</span>
                    </StyledItem>
                  )}
                </>
              )}
              {isSmallerThanTablet && (
                <>
                  <StyledItem>
                    <img className="icon" alt="account icon" src={dashboardIcon} />
                    <Link to="/">Intro</Link>
                  </StyledItem>
                  {authToken && (
                    <>
                      <StyledItem>
                        <img className="icon" alt="account icon" src={navAirdropIcon} />
                        <Link to="/airdrop" onClick={handleAirdopClick}>AirDrop</Link>
                      </StyledItem>
                      <StyledItem>
                        <img className="icon" alt="account icon" src={navContributeIcon} />
                        <Link to="/contribute" onClick={handleContributeClick}>ContriBute</Link>
                      </StyledItem>
                    </>
                  )}
                  {authToken && SHOW_DODO_BIRD && (
                    <StyledItem>
                      <img className="icon" alt="account icon" src={navDodobirdIcon} />
                      <Link to="/dodobird">DoDo Bird</Link>
                    </StyledItem>
                  )}
                  {authToken && (
                    <StyledItem
                      onClick={() => {
                        if (walletAddress) {
                          handleLogout();
                        } else {
                          openModal();
                        }
                      }}
                    >
                      {walletAddress && <img className="icon" alt="account icon" src={logoutIcon} />}
                      <span className="content">{truncatedAddress || 'Connect'}</span>
                    </StyledItem>
                  )}
                </>
              )}
            </StyledGroup>
          </StyledContainer>
        </StyledNav>
      </StyledHeader>
      <SuccessModal
        title={popup?.title}
        opened={popup?.opened}
        onClose={handlePopupClose}
        message={popup?.description}
      />
    </>
  );
};

export default Navbar;
