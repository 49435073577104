const finishDate = new Date('01 Sep 2024 00:00:00 UTC');

export const SHOW_DODO_BIRD = true;
export const IS_FINISHED = new Date() >= finishDate;

const airdropFinishDate = new Date('01 Oct 2024 00:00:00 UTC');
export const IS_AIRDROP_FINISHED = airdropFinishDate.getTime() < Date.now();

const mintFinishDate = new Date('31 Oct 2024 23:59:59 UTC');
export const IS_MINT_FINISHED = mintFinishDate.getTime() < Date.now();
